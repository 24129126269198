<template>
  <div class="users-form">
    <h2 class="edit-text">{{ createOrEdit }}</h2>
    <div>
          <div class="content">
            <form action="" @submit.prevent="saveUserData">
              <md-card-header>
                <div class="md-title"><b></b></div>
                <div class="md-subhead"></div>
              </md-card-header>
              <md-card-content>
                <p class="field-name">{{ $t('name') }} *</p>
                <md-field md-inline>
                  <md-input
                    v-model="name"
                    :class="$route.name === 'users-edit' ? 'name-disable' : ''"
                    required
                    :disabled="$route.name === 'users-edit'"
                    :placeholder="$t('name') "
                  />
                </md-field>
              </md-card-content>

              <md-card-content>
                <p class="field-name">{{ $t('email') }} *</p>
                <md-field md-inline>
                  <md-input v-model="email" required :placeholder="$t('email') "></md-input>
                </md-field>
              </md-card-content>

              <md-card-content class="select-wrap">
                <p class="field-name">{{ $t('role') }} *</p>
                <select name="role" class="select-role" v-model="selected_role" required>
                  <option
                    v-for="role in rolesList"
                    :key="role.id"
                    :selected="selected_role === role.id"
                    :value="role.id"
                  >
                    {{ $t(role.name) }}
                  </option>
                </select>
              </md-card-content>
              <md-card-content class="select-wrap" v-if="selected_role === 3 && userData.roles[0].name === 'admin'">
                <p class="field-name">{{ $t('hierarchical_level') }} *</p>
                <select name="hierarchical" class="select-role" required v-model="hierarchical_level">
                  <option
                      v-for="(value,i) in hierarchical_values"
                      :key="i"
                      :selected="hierarchical_level === i"
                      :value="i"
                  >{{$t(value)}}</option>
                </select>
              </md-card-content>
                <md-card-content class="select-wrap">
                    <p class="field-name">{{ $t('language') }}</p>
                    <select name="role" class="select-role" v-model="selected_language" required v-if="userData">
                        <option
                            v-for="lang in langList[userData.language]"
                            :key="lang"
                            :selected="langList[userData.language][user.language]"
                            :value="lang"
                        >
                            {{lang}}
                        </option>
                    </select>
                </md-card-content>

              <md-card-content class="select-wrap">
                <p class="field-name" v-if="selected_role !== 3">{{ $t('clientUser') }}</p>
                <p class="field-name" v-else>{{ $t('clientUser') }} *</p>
                <select name="role" class="select-role" v-model="client_id" :required="selected_role === 3">
                  <option
                    v-for="client in clientUser"
                    :key="client.id"
                    :selected="client_id === client.id"
                    :value="client.id"
                  >
                    {{ client.name }}
                  </option>
                </select>
              </md-card-content>

              <md-card-content>
                <p class="field-name">{{ $t('password') }} <span v-if="!isEdit">*</span></p>
                <md-field md-inline>
                  <md-input
                    v-model="password"
                    type="password"
                    :required="!isEdit"
                    :placeholder="$t('password') "
                  />
                </md-field>
              </md-card-content>
              <div class="actions">
                <md-button class="md-raised save" type="submit"><span>{{ $t('save') }}</span></md-button>
                <md-button class="md-raised cancel">
                  <router-link to="/users"><span>{{ $t('cancel') }}</span></router-link>
                </md-button>
              </div>
            </form>
            <div class="permissions" v-if="permissions.length > 0">
              <h5>{{ $t('permissions') }}</h5>
              <ul>
                <li v-for="permission in permissions" :key="permission.id">{{ $t(permission.name) }}</li>
              </ul>
            </div>
            <div v-else class="permissions">
              <h5>{{ $t('noPermission') }}</h5>
            </div>
          </div>
          <div>
            <Messages :messages="messages"></Messages>
            <Errors :errors="errors"></Errors>
          </div>
    </div>
  </div>
</template>

<script>
import {errors} from "@/mixins/errors"
import {messages} from "@/mixins/messages"
import {printArray} from "@/mixins/printArray"
import {userPermissions} from '@/mixins/userPermissions';
import {mapActions, mapGetters, mapMutations} from "vuex";
import Errors from "@/components/Errors"
import Messages from "@/components/Messages"

export default {
  name: "Form",
  mixins: [
    printArray,
    errors,
    messages,
    userPermissions
  ],
  components: {
    Errors,
    Messages
  },
  data: () => ({
    name: '',
    email: '',
    password: '',
    permissions: [],
    role_name: '',
    selected_role: '',
    selected_language: '',
    hierarchical_level: 0,
    hierarchical_values: [
      'hierarchical_level_specialist',
      'hierarchical_level_manager',
    ],
    languages: [],
      langList: {
          'en' : {'en': 'English', 'ru': 'Russian'},
          'ru' : {'en': 'Английский', 'ru': 'Русский'},
      },
    client_id: '',
    authUser : JSON.parse(localStorage.getItem('user'))
  }),

  computed: {
    ...mapGetters(['userData', 'user', 'saveMessages', 'saveErrors', 'clientsList', 'rolesList']),
    path() {
      let pathName;
      if(this.$route.name === 'users-create'){
        pathName = "/users/create"
      }else if(this.$route.name === 'users-edit'){
        pathName = `/users/edit/${this.$route.params.id}`
      }
      return pathName
    },
    createOrUpdate() {
      return (this.$route.name === 'users-create') ? '/users/create' : '/users/' + this.user.id + '/update';
    },
    createOrEdit() {
      let name;
      if (this.$route.name === 'users-create') {
        name = this.$t('createUser')
      } else if (this.$route.name === 'users-edit') {
        name = this.$t('editUser')
      }
      return name
    },
    isEdit() {
      return !!this.$route.params.id
    },
    clientUser() {
      let data = []
      if (this.selected_role === 3) {
        return this.clientsList
      }
      return data
    }
  },
  watch:{
    userData: {
      deep: true,
      handler (val) {
          if (val){
              this.selected_language = this.langList[val.language][this.user.language];
          }
      }
    },
    $route() {
      this.clearErrors()
      this.clearMessages()
    },
    saveMessages: {
      deep: true,
      handler(val) {
        if(val) {
          this.addMessage(val);
          this.$router.push({name: 'user-list'});
        }
      }
    },
    saveErrors: {
      deep: true,
      handler(val) {
          const errors = {}
          if (val.email) {
              Object.assign(errors, {email: [this.$t(val.email[0])]})
          }

          if (val.name) {
              Object.assign(errors, {name: [this.$t(val.name[0])]})
          }
        this.setErrors(errors);
      }
    },
    selected_role: {
      immediate: true,
      handler() {
        let roles = [...this.rolesList];
        roles.filter(role => {
          if(role.id === this.selected_role){
            this.permissions = role.permissions
          }
        });
      }
    }
  },
  methods: {
    ...mapActions(['getUser', 'saveUser', 'getRolesList']),
    ...mapMutations(['clearErrorsMessages']),
    async saveUserData() {
      this.clearErrors()
      this.clearMessages()
      this.clearErrorsMessages();
        if (this.selected_language === 'Russian' || this.selected_language === 'Русский'){
            this.selected_language = 'ru'
        }
        if (this.selected_language === 'English' || this.selected_language === 'Английский'){
            this.selected_language = 'en'
        }
      let data = {
        name: this.name,
        email: this.email,
        role_id: this.selected_role,
        language: this.selected_language,
        password: this.password,
        hierarchical_level: this.hierarchical_level
      };

      if(this.client_id) {
        data.client_id = this.client_id
      }

      if(!this.isEdit) {
        data.password = this.password
      }


     this.saveUser({url: this.createOrUpdate, data})
   },
      setLanguage(){
          this.selected_language = this.langList[this.userData.language][this.user.language];
      },
    async getRoles() {
      await this.getRolesList()
          .catch(err => {
            this.setErrors(err.response.data.errors);
          })
    },
      async getLanguages() {
          await this.$axios
              .get(process.env.VUE_APP_API_URL + "/users/languages/list")
              .then(response => {
                  this.languages = response.data;
              })
              .catch(error => console.log('error', error));
    },
  },
  async mounted() {

    let userId = this.$route.params.id;

    await this.getRoles();
    // await this.getLanguages();

    if (userId){
      await this.getUser(userId);
      this.name = this.user.name;
      this.email = this.user.email;
      this.password = this.user.password;
      this.selected_role = this.user.role;
      this.hierarchical_level = this.user.hierarchical_level;
      this.setLanguage()

      this.client_id = this.user.client_id;
    }
    else if (this.userData.language === 'ru') {
        this.selected_language = 'Русский';
    }
    else if (this.userData.language === 'en') {
        this.selected_language = 'Russian';
    }
  },

}
</script>

<style lang="scss">
.users-form {
  margin-left: 21px;
  p {
    margin: 0;
  }

  .select-wrap {
    margin-bottom: 20px;
  }

  .md-tabs.md-theme-default .md-tabs-navigation{
    background: unset;
    border-bottom: 2px solid grey;
  }

  .md-tabs.md-theme-default .md-tabs-indicator{
    background-color: #054F63!important;
    top: 48px;
  }

  .md-tabs.md-theme-default .md-tabs-navigation .md-button{
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }

  .name-disable{
    background: #939393;
    opacity: .5;
    margin-top: -0.2px;
    border-radius: 4px;
  }

  .save {
    span {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .md-content.md-theme-default{
    background: unset;
  }

  .md-tabs.md-theme-default .md-tabs-navigation .md-button.md-active{
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
  }
  .md-button {
    height: 48px;
    margin: 0 8px;
  }

  .select-role {
    width: 63%;
    max-width: 418px;
    background: #FFFFFF;
    border: 1px solid #CDD0D9;
    outline: none;
    box-sizing: border-box;
    border-radius: 4px;
    margin-left: 15px;
    height: 50px;
    padding: 0 0 0 25px;
    font-size: 14px;

    option {
      height: 50px;
    }
  }

  .content {
    display: flex;
  }

  .actions {
    display: flex;
    margin-left: 112px;
    width: 63%;
    max-width: 418px;

    @media(max-width: 1440px) {
      display: flex;
      justify-content: space-between;
    }

    .save {
      background: #EC8B31!important;
      border-radius: 6px;

      span {
        color: white;
      }
    }

    .cancel {
      margin-left: 10px;
      border-radius: 6px;

      @media(max-width: 1440px) {
        margin-left: 0;
      }

      span {
        color: black;
      }
    }

    span {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      padding: 12px 32px;
    }
  }

  .md-ripple {
    .md-icon.md-theme-default.md-icon-font {
      display: none !important;
    }
  }

  .md-menu.md-select {
    padding-left: 25px;
    padding-top: 5px;
  }

  .md-card-content {
    //margin-left: 21px;
    display: flex;
    padding: 0 !important;
  }

  .edit-text {
    font-weight: 900;
    font-size: 36px;
    line-height: 52px;
  }

  .md-field:after, .md-field:before {
    position: unset !important;
  }

  .md-card-content {
    width: 100%;
    //justify-content: space-evenly;

    p {
      padding-top: 17px;
      width: 105px;
      text-align: right;
    }

    //.md-has-password {
    //  margin-left: -20px;
    //}
  }

  .md-card.md-theme-default {
    background: unset;
    width: 50%;
  }

  .md-card {
    box-shadow: unset !important;
  }

  .md-select-value {
    position: absolute;
    top: 6px;
    left: 25px;
  }

  .md-field {
    width: 63%;
    max-width: 418px;
    background: #FFFFFF;
    border: 1px solid #CDD0D9;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0;
    margin: 0 0 20px 15px  !important;

    .md-input {
      height: 50px !important;
      padding: 0 !important;
      padding-left: 25px !important;
      font-size: 14px;
    }


    label {
      position: absolute;
      top: 14px;
      padding-left: 25px;
    }
  }

  .permissions{

    h5{
      //margin-left: 186px;
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 34px;
      color: #000000;

      @media(max-width: 1440px) {
        margin-left: 100px;
      }
    }

    ul{

      li{
        display: block;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
      }
    }
  }
}

</style>